<style type="text/css">
  .slick-list {height: 600px!important;}
  .slick-prev:before, .slick-next:before{color: #333!important; font-size: 80px!important;font-weight: bold!important; top:-40px!important;}
  .slick-prev::before {content:  url("https://mypathkr.com/img/arrow_back.png")!important ;}
  .slick-next::before {content:  url("https://mypathkr.com/img/arrow_next.png")!important ;}
  .slick-prev, .slick-next { top:30%!important;}
  a:hover .test_card1{content:url(https://mypathkr.com/main/img/img_k_review_01.png); margin-top: -60px;}
  a:hover .test_card2{content:url(https://mypathkr.com/main/img/img_k_review_02.png); margin-top: -60px;}
  a:hover .test_card3{content:url(https://mypathkr.com/main/img/img_k_review_03.png); margin-top: -60px;}
  a:hover .test_card4{content:url(https://mypathkr.com/main/img/img_k_review_04.png); margin-top: -60px;}
  a:hover .test_card5{content:url(https://mypathkr.com/main/img/img_k_review_05.png); margin-top: -60px;}
  a:hover .test_card6{content:url(https://mypathkr.com/main/img/img_k_review_06.png); margin-top: -60px;}
  .review_box {border:none; }
  .bx-wrapper {margin: 0!important; padding:0!important; height: 460px;}
  .bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {left: -160px;}
  .bx-wrapper {border:none!important; background: none!important;}
  .bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {bottom:-18px!important;}
  .modal {top:113px!important; right: 1150px!important;}
  .bx-wrapper .bx-pager.bx-default-pager a {width: 0px!important;}
  hr {
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
 .verti-line {height: 50px; width: 0px; border-right: 0px;}
</style>

<template>
<picture>
  <source
    srcset="https://www.childu.co.kr/campus_mobile/img/brand_page.png"
    media="(min-width:365px) and (max-width:767px)"
  />
  <img style="width: 100%" src="" alt="" />
</picture>
<div class="section__01" style="margin-top:-30px;">            
  <div class="inner">
    <div class="wow fadeInUp" data-wow-delay="0.4s">
      <p class="t_1 ">영어를 배우는 목적은</p><br>
      <p class="t_4">시험 성적이 아닌</p><br>
      <span class="t_1_c">의사소통과 지식습득</span><span class="t_1_300">입니다.</span>
    </div>    
    <div class="wow fadeInUp" data-wow-delay="0.8s">
      <p class="t_2">"우리 아이 영어 왜 가르치세요?"</p>
      <p class="t_3">
        라고 물으면 대부분의 학부모님들은<br>"영어로 의사소통이 자유로웠으면 좋겠어요."<br>라고 대답합니다.<br><br>
        <span class="t_3_line">즉, 영어로 자유로이 의사소통 할 수 있는 것,</span><br>
        그리고 자유로운 영어실력을 통해 궁극적으로<br><span class="t_3_line"> 더 나은, 더 많은 지식을 습득하는 것이</span><br>
        영어를 배우는 진짜 목적입니다.
      </p>
    </div>    
  </div>    
</div>

<div class="section_02" style="height:auto;">            
  <div class="inner">
    <div class="wow fadeInUp" data-wow-delay="0.4s">
      <span class="t_1">
        차일드유<span class="t_1_c">란?</span>
      </span><br><br>
    </div>    
  <div class="wow fadeInUp" data-wow-delay="0.8s">
    <p class="t_3">
      시험을 보고 같은 98점을 받으면<br> 같은 반에 배정되어<br> 같은 선생님께 같은 수업을 듣습니다.<br><br>

      그런데 어떤 학생은 2점을 '문법'에서,<br>
      어떤 학생은 2점을 '독해'에서 틀렸다면<br>
      같은 점수를 맞았지만 모르는 것이 같은 것 일까요?
    </p>
      <br><br>
      <hr class="verti-line">
    <p class="t_2" style="font-weight:500; margin-top: 50px; ">
      정답은 <strong style="font-weight:900;">'NO'</strong> 아닙니다.<br><br>

      같은 수업을 들어도<br>
      학생마다 수업에 내용에 대한 이해도 차이는 모두 다를 수 밖에 없습니다.<br><br>
    </p>
  </div>    
  </div> 
</div> 

<div class="section_03">            
  <div class="inner">
    <div class="wow fadeInUp" data-wow-delay="0.3s">
      <span class="t_1">왜 <span class="t_1_c" style="color:#0c3276;">차일드유 영어학원</span>이어야 할까요?</span><br><br>
    </div>    
    <div>
      <p class="t_2">
      다음 중 한 가지라도 고민해보신 적이 있으시다면,<br> 차일드유 영어학원을 생각해봐야 할 때입니다.
      </p>
    </div>  
    <img src="https://www.mypathkr.com/main/img/img_section_03_01.png" class="wow fadeInUp" data-wow-delay="0.4s">
    <img src="https://www.mypathkr.com/main/img/img_section_03_02.png" class="wow fadeInUp" data-wow-delay="0.4s">
    <img src="https://www.mypathkr.com/main/img/img_section_03_03.png" class="wow fadeInUp" data-wow-delay="0.4s">
    <img src="https://www.mypathkr.com/main/img/img_section_03_04.png" class="wow fadeInUp" data-wow-delay="0.4s">
    <img src="https://www.mypathkr.com/main/img/img_section_03_05.png" class="wow fadeInUp" data-wow-delay="0.4s">
  </div> 
</div>

<div class="section_04">            
  <div class="inner">
    <div class="inner-04" style="background: #fff; padding: 40px 0 60px 0; width: 10  0%; height: auto; text-align: center; margin: 0 auto;">
      <div>
        <span class="t_3" style="font-size:18px;">
          작은 학원이라도<br>
          영어의 레벨과 학년과 수준을<br>
          모두 해결 할 수 있는
        </span><br><br>
        <div style="padding-bottom: 40px;" class="wow fadeInUp" data-wow-delay="0.8s">
          <span class="t_1">대한민국 유일한 학원은<br>
          <span class="t_1_c">차일드유</span>입니다.
          </span>
      </div>    
      <p class="t_3">
        50분의 수업 시간 동안 한 학생이 모르는 내용은 약 10분.<br>
        한 명의 학생에게 수업 내 주어지는 정보의 시간은 <br>
        고작 <strong style="font-weight:500;">'10분'</strong>밖에 될 수 없는 현실 속에서<br><br>

        이제 획일적인 진도 중심 수업이 아닌<br>
        학생 한 명, 한 명의 레벨과 학년과 수준에 맞춰<br>
        수업을 제공해줄 수 있기 때문입니다.
      </p>
      </div> 
    </div>      
  </div> 
</div>

<div class="section_05">            
  <div class="inner">
    <div class="wow fadeInUp" data-wow-delay="0.3s">
      <span class="t_1">차일드유 학원</span>&nbsp;&nbsp;&nbsp;
      <span class="t_1">5계명</span>
    </div>    
    <div class="wow fadeInUp" data-wow-delay="0.6s">
      <img src="https://www.mypathkr.com/main/img/img_section5.png" style="padding-top: 50px;">
    </div>    
  </div> 
</div>

<div class="section_06" style="height:auto; background-position: center center;">            
  <div class="inner">
    <div class="wow fadeInUp" data-wow-delay="0.4s">
      <p class="t_1">ChildU는</p><br>
      <p class="t_1">원어민처럼 영어가 자유로운 학생도</p><br>
      <p class="t_1">다닐 수 있습니다.</p>
      <p class="t_3" style="padding-top: 50px;">
        "학원에 개설된 반 중 들어갈 수 있는 반이 없습니다."<br>
        영어가 자유로운 초등학교 5학년 학생이 <br>
        학원에서 테스트를 받은 후 듣게 된 이야기입니다.<br><br>

        영어를 잘하는 학생이 와도<br>
        영어학원에 들어갈 수 있는 반이 없는 현실<br><br>

        잘하는 한 명의 학생을 위해서 새로운 반을 구성할 수도,<br>
        가르칠 선생님이 없다는 어려움도,<br>
        100여 명의 원어민 선생님이 대기 중인 ChildU에서는 해결할 수 있습니다.
      </p>
      <!--
      <span class="lnk a"><span></span><a href="/sub/MyPath/" target="_blank">자세히보기&nbsp;&nbsp;&nbsp;></a></span>
      -->
    </div>     
  </div>    
</div>

<div class="section_07" style="height:auto;">            
  <div class="inner">
    <div class="wow fadeInUp" data-wow-delay="0.4s">
      <p class="t_1">A, B, C, D 알파벳을 모르는</p><br>
      <p class="t_1">영어를 처음 시작하는 학생들도</p><br>
      <p class="t_1">가능합니다.</p><br>
      <p class="t_3">
        "자유롭게 의사소통을 할 수 있어야 영어를 잘하는 것입니다."<br><br>
        영어권 국가의 사람들이 학습하지 않아도 <br>
        영어로 듣고 말하기가 자유로운 이유는 환경입니다.<br>
        미국에서 생활하는 것과 같은 환경을 제공해주는 것이<br>
        영어를 정복하는 가장 빠른 방법이죠.<br><br>
        <p class="t_3" style="font-weight: 500;">ChildU에서는 미국 현지 학교 수업 그대로를 제공합니다.</p>
      </p>
    </div>     
  </div>    
</div>
<div class="section_08" style="width:100%; background:#f0f0f0; padding-bottom:100px; overflow: hidden;">
  <div class="inner">
    <p class="t_0" style="margin-bottom: 0px;">한국 학생들을 위한</p><br>
    <p class="t_1" style="margin-bottom: 50px;">학습 보조 시스템</p>
      <div>
        <img src="https://www.mypathkr.com/main/img/img_slide_cu1.png" class="col-md-6">
      </div>
      <div>
        <img src="https://www.mypathkr.com/main/img/img_slide_cu2.png" class="col-md-6">
      </div>
      <div>
        <img src="https://www.mypathkr.com/main/img/img_slide_cu3.png" class="col-md-6">
      </div>
      <div>
        <img src="https://www.mypathkr.com/main/img/img_slide_cu4.png" class="col-md-6">
      </div>
  </div>
</div>

<div class="section_09" style="width:100%; background:#fff; padding-bottom:100px; overflow: hidden;">
  <div class="inner">
    <p class="t_0" style="margin-bottom: 0px;">결과를 이끌어내는</p><br>
    <p class="t_1" style="margin-bottom: 50px;">교사용 키트 및 학습데이터</p>
    <div>
      <img src="https://www.mypathkr.com/main/img/img_slide_cu5.png" class="col-md-6">
    </div>
    <div>
      <img src="https://www.mypathkr.com/main/img/img_slide_cu6.png" class="col-md-6">
    </div>
  </div>
</div>
</template>

<script type="text/javascript">
  $(document).on('ready', function() {
    $(".vertical-center-4").slick({
      vertical: true,
      centerMode: true,
      slidesToShow: 4,
      slidesToScroll: 2
    });
    $(".vertical-center-3").slick({
      vertical: true,
      centerMode: true,
      slidesToShow: 3,
      slidesToScroll: 3
    });
    $(".vertical-center-2").slick({
      vertical: true,
      centerMode: true,
      slidesToShow: 2,
      slidesToScroll: 2
    });
    $(".vertical-center").slick({
      vertical: true,
      centerMode: true,
    });
    $(".vertical").slick({
      vertical: true,
      slidesToShow: 3,
      slidesToScroll: 3
    });
    $(".regular").slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3
    });
    $(".center").slick({
      autoplay:true,
      infinite: true,
      centerMode: true,
      slidesToShow: 3,
      slidesToScroll: 3
    });
      $(".center_2").slick({
      autoplay:true,
      infinite: true,
      centerMode: true,
      slidesToShow: 1,
      
      slidesToScroll: 3
    });
      $(".center_3").slick({
      autoplay:true,
      infinite: true,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 3
    });
      
    $(".variable").slick({
      infinite: true,
      variableWidth: true
    });
    $(".lazy").slick({
      lazyLoad: 'ondemand', // ondemand progressive anticipated
      infinite: true
    });
  });
</script>