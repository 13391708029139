<style></style>

<template>
  <picture>
    <source
      srcset="https://www.childu.co.kr/campus_mobile/img/m_program_childu.png"
      media="(min-width:365px) and (max-width:767px)"
    />
    <img style="width: 100%" src="" alt="" />
  </picture>
  <div id="study-page">
    <div class="section_07" style="height: 800px; margin-top: -30px">
      <div class="inner">
        <div
          class="wow fadeInUp"
          data-wow-delay="0.4s"
          style="padding-top: 10px"
        >
          <p class="t_1">자유롭게</p>
          <br />
          <p class="t_1">의사소통을 할 수 있어야</p>
          <br />
          <p class="t_1">영어를 잘하는 것입니다.</p>
          <br />
          <p
            style="
              font-weight: 300;
              color: #fff;
              font-size: 20px;
              padding-top: 40px;
            "
          >
            영어권 국가의 사람들이 학습하지 않아도<br />
            영어로 듣고 말하기가 자유로운 이유는 환경입니다.<br />
            미국에서 생활하는 것과 같은 환경을 제공해주는 것이<br />
            영어를 정복하는 가장 빠른 방법이죠.
          </p>
          <br />
          <p style="font-weight: 500; color: #fff; font-size: 20px">
            차일드유에서는 미국 현지 학교 수업 그대로를 제공합니다.<br />
            주어지는 환경이 달라지면 레벨이 아니라 실력이 달라집니다
          </p>
        </div>
      </div>
    </div>

    <div
      class="section_07_myuse"
      style="
        height: auto;
        background: url(https://mypathkr.com/img/bg_myuse_section_02.png);
      "
    >
      <div
        class="inner"
        style="text-align: center; margin: 0 auto; width: 100%"
      >
        <div
          class="wow fadeInUp"
          data-wow-delay="0.4s"
          style="padding-top: 10px"
        >
          <p class="t_1" style="font-weight: 600; text-align: center">
            ChildU란?
          </p>
          <br /><br /><br />
          <p class="t_1" style="text-align: center">
            영어가 모국어인 미국 학생들에게
          </p>
          <br />
          <p class="t_1" style="text-align: center">
            교과지식을 가르치기 위해 만든
          </p>
          <br />
          <p class="t_1" style="text-align: center; padding-bottom: 20px">
            프로그램입니다.
          </p>
          <br />
          <hr class="verti-line" />
          <p
            style="
              font-weight: 300;
              color: #fff;
              font-size: 20px;
              padding-top: 40px;
              text-align: center;
            "
          >
            <strong style="font-size: 20px; font-weight: 700">즉,</strong><br />
            한국인을 위한 배려도 한국식 암기 교육도 들어있지 않습니다.<br />
            미국 학생들에게 미국 선생님들이 미국교과서로 전 과목을 강의해 <br />
            유학과 같은 환경을 제공할 수 있는 것 입니다.
          </p>
        </div>
      </div>
    </div>

    <div
      style="
        background: url(https://mypathkr.com/img/bg_myuse_0222.png);
        height: 825px;
      "
    >
      <div style="text-align: center; margin: 0 auto; padding-top: 100px">
        <img
          class="use-07-title"
          src="https://mypathkr.com/img/title_myuse_03.png"
          style="width: 50%"
        />
        <p style="font-weight: 300; color: #fff; font-size: 20px">
          <br /><br />환경에 적응하기 위한 처음의
          <span style="font-weight: 500; color: #fff; font-size: 20px"
            >'낯섦'</span
          >, 언어를 습득하기 위한
          <span style="font-weight: 500; color: #fff; font-size: 20px">
            필수 불가결한 단계</span
          >입니다.<br /><br />

          <span style="font-weight: 400; color: #fff; font-size: 20px"
            >“이게 무슨 말이야?”<br />
            “이걸 어떻게 우리 아이가 하지?”<br />
            “뭐라고 하는거야?, 알아듣기는 할까?”<br /><br />
            부모님들의 반응은 한결같습니다.<br />
            그러나 아이들은 누구보다 빠르게 적응하고 수업을 해나갑니다.<br /><br
          /></span>

          미국 현지 원어민 선생님들의 강의 속도도,<br />
          화려한 그래픽보다 강의와 지식 전달에 충실한 내용도,<br />
          한국의 암기식, 풀이식 수업과 다른 새로운 수업 방식도,<br />
          아이들은 그대로 흡수하고 습득합니다.<br />
        </p>
      </div>
    </div>

    <div
      class="section_07_myuse2"
      style="
        background: #fff;
        height: 830px;
        text-align: center;
        margin: 0 auto;
        padding: 80px 0px;
      "
    >
      <img
        class="use-07-title2"
        src="https://mypathkr.com/img/title_chlidu_02.png"
      />
      <div
        class="section_07_myuse2_block"
        style="
          text-align: center;
          margin: 0 auto;
          padding-top: 60px;
          width: 70%;
        "
      >
        <div style="display: block" class="col-md-3" data-wow-delay="0.4s">
          <img src="https://mypathkr.com/img/img_card_cont_02_01.png" />
          <p style="font-size: 20px; line-height: 25px; padding-top: 40px">
            영어 시험이 아니라<br />영어 실력을<br />향상시키고 싶은 학생
          </p>
        </div>
        <div style="display: block" class="col-md-3" data-wow-delay="0.6s">
          <img src="https://mypathkr.com/img/img_card_cont_02_02.png" />
          <p style="font-size: 20px; line-height: 25px; padding-top: 40px">
            미국교과과정을 기반으로<br />듣고 말하고 읽고 쓰는 영어를 습득하고자
            하는 학생
          </p>
        </div>
        <div style="display: block" class="col-md-3" data-wow-delay="0.8s">
          <img src="https://mypathkr.com/img/img_card_cont_02_03.png" />
          <p style="font-size: 20px; line-height: 25px; padding-top: 40px">
            문학, 비문학 작품을<br />비판적으로 읽고, 영작능력을 강화하고자 하는
            학생
          </p>
        </div>
        <div style="display: block" class="col-md-3" data-wow-delay="0.9s">
          <img src="https://mypathkr.com/img/img_card_cont_02_04.png" />
          <p style="font-size: 20px; line-height: 25px; padding-top: 40px">
            영어 유치원 재원생,<br />국제학교 및<br />유학을 준비하는 학생
          </p>
        </div>
      </div>
    </div>

    <div
      class="section_07_myuse3"
      style="
        background: url(https://mypathkr.com/img/bg_myuse_02.png);
        height: 1200px;
      "
    >
      <div
        class="img-title"
        style="text-align: center; margin: 0 auto; padding-top: 150px"
      >
        <img src="https://mypathkr.com/img/img_title_03.png" />
      </div>
      <div style="width: 100%; text-align: left; margin: 0 auto; height: 500px">
        <div class="circle-img" style="width: 60%; float: right">
          <img
            src="https://mypathkr.com/img/img_myuse_02.png"
            style="padding-bottom: 50px"
          />
          <p
            style="
              color: #fff;
              font-weight: 300;
              font-size: 20px;
              line-height: 30px;
              letter-spacing: -0.05em;
            "
          >
            ChildU로 진급하기 위한 과정입니다.<br />
            미국 공통교과과정 K~5학년에서 배우는 교과목들을(Language Arts, Math,
            Science, Social Studies)<br />전공 교사들의 직접 인강과 애니메이션
            강의 등의 다양한 강의를 통해 듣고, 쓰고,<br />
            읽고, 말하기의 언어적인 구사능력과 미국 교과지식을 동시에 향상
            시키는 것을 목표로 합니다.
          </p>
        </div>
      </div>
      <div
        class="img-title"
        style="text-align: center; margin: 0 auto; padding-top: 50px"
      >
        <img src="https://mypathkr.com/img/img_title_03_2.png" />
      </div>
      <div style="width: 100%; text-align: left; margin: 0 auto; height: 200px">
        <div class="circle-img" style="width: 60%; float: right">
          <p
            style="
              color: #fff;
              font-weight: 300;
              font-size: 20px;
              line-height: 30px;
              letter-spacing: -0.05em;
            "
          >
            <span style="color: #fff; font-weight: 500; font-size: 20px"
              >01</span
            >&nbsp;&nbsp;미국 학력인증기관 Cognia를 통해 인증된 현지 학교
            프로그램으로 미국학교 수업을<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            그대로 들을 수 있습니다.<br />
            <span style="color: #fff; font-weight: 500; font-size: 20px"
              >02</span
            >&nbsp;&nbsp;수학, 과학 등 교과목 학습을 영어로 배우며 교과지식과
            영어를 동시에 습득합니다.<br />
            <span style="color: #fff; font-weight: 500; font-size: 20px"
              >03</span
            >&nbsp;&nbsp;아직 영어가 자유롭지 않은 학생들이 강의 속 단어, 문장을
            발화, 예복습 할 수 있도록<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;언어훈련과정이
            포함되어 있습니다.<br />
            <span style="color: #fff; font-weight: 500; font-size: 20px"
              >04</span
            >&nbsp;&nbsp;학습포인트로 나만의 아바타를 꾸밀 수 있어 즐거운 학습
            동기부여가 됩니다.
          </p>
        </div>
      </div>
    </div>

    <div
      class="section_07_myuse4"
      style="height: 620px; text-align: center; margin: 0 auto"
    >
      <div style="text-align: center; margin: 0 auto; padding-top: 100px">
        <img
          class="use-07-title4"
          src="https://mypathkr.com/img/img_title_04.png"
        />
        <div class="card-header" style="width: 100%; margin: 0 auto">
          <div class="section_08" style="padding-top: 50px !important">
            <div class="inner" style="width: 50%">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel with-nav-tabs panel-default">
                    <div class="panel-heading">
                      <ul class="nav nav-tabs">
                        <li class="active col-md-2">
                          <a href="#tab1default" data-toggle="tab">Level 1</a>
                        </li>
                        <li class="col-md-2">
                          <a href="#tab2default" data-toggle="tab">Level 2</a>
                        </li>
                        <li class="col-md-2">
                          <a href="#tab3default" data-toggle="tab">Level 3</a>
                        </li>
                        <li class="col-md-2">
                          <a href="#tab4default" data-toggle="tab">Level 4</a>
                        </li>
                        <li class="col-md-2">
                          <a href="#tab5default" data-toggle="tab">Level 5</a>
                        </li>
                        <li class="col-md-2">
                          <a href="#tab6default" data-toggle="tab">Level 6</a>
                        </li>
                      </ul>
                    </div>
                    <div class="panel-body">
                      <div
                        class="tab-content"
                        style="padding: 25px 0px 20px 70px"
                      >
                        <div class="tab-pane fade in active" id="tab1default">
                          <p
                            style="
                              text-align: left;
                              font-weight: 400;
                              font-size: 16px;
                              line-height: 28px;
                            "
                          >
                            ①&nbsp;&nbsp;알파벳 순서를 이해하고, 단모음, 단자음,
                            이중자음, 이중모음을 모국어 습득순서로 음가를 이해
                            하고 조합할 수 있다.<br />
                            ②&nbsp;&nbsp;문장을 구성하기 위해 필요한 짧은 문장을
                            읽고, 이해할 수 있다.
                          </p>
                        </div>
                        <div class="tab-pane fade in" id="tab2default">
                          <p
                            style="
                              text-align: left;
                              font-weight: 400;
                              font-size: 16px;
                              line-height: 28px;
                            "
                          >
                            ①&nbsp;&nbsp;장모음, 이중자음, 이중모음들이 만들어
                            지는 소리들을 구별할 수 있다.<br />
                            ②&nbsp;&nbsp;문장을 구성하기 위해 필요한 패턴들을
                            이해하여 기초문장을 읽고, 쓸 수 있다.<br />
                            ③&nbsp;&nbsp;미국 유치학년 수준의 문학, 비문학
                            작품들을 읽고, 이해할 수 있으며
                            미국공통교과과정(CCSS) 에서 요구하는 비판적인<br />독서스킬들을
                            습득할 수 있다.
                          </p>
                        </div>
                        <div class="tab-pane fade in" id="tab3default">
                          <p
                            style="
                              text-align: left;
                              font-weight: 400;
                              font-size: 16px;
                              line-height: 28px;
                            "
                          >
                            ①&nbsp;&nbsp;자음, 모음이 결합하여 불규칙하게 만들어
                            지는 소리들을 이해하고 구분할 수 있다.<br />
                            ②&nbsp;&nbsp;읽기가 편하고, 독해 전략과 논픽션
                            리딩을 통해 영어의 기본기를 완성할 수 있다.<br />
                            ③&nbsp;&nbsp;미국 1학년 수준의 문학, 비문학 작품들을
                            읽고, 이해할 수 있으며 미국공통교과과정(CCSS)에서
                            요구하는 비판적인<br />독서스킬들을 습득할 수 있다.
                          </p>
                        </div>
                        <div class="tab-pane fade in" id="tab4default">
                          <p
                            style="
                              text-align: left;
                              font-weight: 400;
                              font-size: 16px;
                              line-height: 28px;
                            "
                          >
                            ①&nbsp;&nbsp;미국 2학년 수준의 문학, 비문학 작품들을
                            읽고, 이해할 수 있으며 미국공통교과과정(CCSS)에서
                            요구하는 비판적인<br />독서스킬들을 습득할 수
                            있다.<br />
                            ②&nbsp;&nbsp;듣기, 읽기가 편하고, 독해 전략과 논픽션
                            리딩을 통해 영어의 기본기를 확장할 수 있다.<br />
                            ③&nbsp;&nbsp;미국 2학년 수준의 사회, 과학 교과목에서
                            다루어지는 다양한 주제들에 대한 전문 어휘와 문장들을
                            읽고, 말할 수 있다.
                          </p>
                        </div>
                        <div class="tab-pane fade in" id="tab5default">
                          <p
                            style="
                              text-align: left;
                              font-weight: 400;
                              font-size: 16px;
                              line-height: 28px;
                            "
                          >
                            ①&nbsp;&nbsp;미국 3학년 수준의 문학, 비문학 작품들을
                            읽고, 이해할 수 있으며 미국공통교과과정(CCSS)에서
                            요구하는 비판적인<br />독서스킬들을 습득할 수
                            있다.<br />
                            ②&nbsp;&nbsp;듣기, 읽기가 편하고, 영어를 영어로
                            이해할 수 있으며, 원서 교과목으로 교과지식을 습득할
                            수 있다.<br />
                            ③&nbsp;&nbsp;미국 3학년 수준의 사회, 과학 교과목에서
                            다루어지는 다양한 주제들에 대한 전문 어휘와 문장들을
                            읽고, 말할 수 있다.
                          </p>
                        </div>
                        <div class="tab-pane fade in" id="tab6default">
                          <p
                            style="
                              text-align: left;
                              font-weight: 400;
                              font-size: 16px;
                              line-height: 28px;
                            "
                          >
                            ①&nbsp;&nbsp;미국 4, 5학년 수준의 문학, 비문학
                            작품들을 읽고, 이해할 수 있으며
                            미국공통교과과정(CCSS)에서 요구하는 비판적인<br />독서스킬들을
                            습득할 수 있다.<br />
                            ②&nbsp;&nbsp;듣기, 읽기가 편해서 영어를 영어로
                            이해하고 영어로 요약해서 발표 할 수 있다.<br />
                            ③&nbsp;&nbsp;미국 4, 5학년 수준의 사회, 과학
                            교과목에서 다루어지는 다양한 주제들에 대한 전문
                            어휘와 문장들을 읽고, 말할 수 있다.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        background: #f9f9f9;
        height: auto;
        text-align: center;
        padding: 100px 0;
        margin: 0 auto;
      "
    >
      <div style="width: 100%; text-align: center; margin: 0 auto">
        <img
          class="use-07-title5"
          src="https://mypathkr.com/img/img_title_cu04.png"
        /><br />
        <img
          class="img_cont_06"
          src="https://mypathkr.com/img/img_cont_06.png"
          style="padding-top: 70px; width: 50%"
        />
      </div>
    </div>

    <div
      style="
        background: #fff;
        height: auto;
        text-align: center;
        margin: 0 auto;
        padding: 80px;
      "
    >
      <div style="width: 100%; text-align: center; margin: 0 auto">
        <img
          class="use-07-title6"
          src="https://mypathkr.com/img/img_title_cu05.png"
        /><br />
        <img
          class="img_cont_07"
          src="https://mypathkr.com/img/img_cont_07.png"
          style="padding-top: 70px; width: 50%"
        />
      </div>
    </div>
  </div>
</template>
