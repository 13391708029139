<style></style>

<template>
  <div id="curri-page" style="text-align: center; margin: 0 auto">
    <div class="card-header">
      <div class="section_08 curri-section">
        <div class="inner" style="width: 60%">
          <p class="t_0" style="font-size: 20px; margin-bottom: -10px">
            ChildU Curriculum
          </p>
          <br />
          <p class="t_1" style="margin-bottom: 50px">커리큘럼</p>
          <div class="row">
            <div class="col-md-12">
              <div class="panel with-nav-tabs panel-default">
                <div class="panel-heading">
                  <ul class="nav nav-tabs">
                    <li class="active col-md-3">
                      <a href="#tab1default" data-toggle="tab">Language Arts</a>
                    </li>
                    <li class="col-md-3">
                      <a href="#tab2default" data-toggle="tab">Math</a>
                    </li>
                    <li class="col-md-3">
                      <a href="#tab3default" data-toggle="tab"
                        >Social Studies</a
                      >
                    </li>
                    <li class="col-md-3">
                      <a href="#tab4default" data-toggle="tab">Science</a>
                    </li>
                  </ul>
                </div>
                <div class="panel-body">
                  <div class="tab-content" style="padding: 20px 20px 0px 20px">
                    <div class="tab-pane fade in active" id="tab1default">
                      <img
                        src="https://mypathkr.com/img/myuse_curri_01.png"
                        style="width: 100%"
                      />
                    </div>
                    <div class="tab-pane fade in" id="tab2default">
                      <img
                        src="https://mypathkr.com/img/myuse_curri_02.png"
                        style="width: 100%"
                      />
                    </div>
                    <div class="tab-pane fade in" id="tab3default">
                      <img
                        src="https://mypathkr.com/img/myuse_curri_03.png"
                        style="width: 100%"
                      />
                    </div>
                    <div class="tab-pane fade in" id="tab4default">
                      <img
                        src="https://mypathkr.com/img/myuse_curri_04.png"
                        style="width: 100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
