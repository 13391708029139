<template>
<div class="row">
<div class="col-md-12" style="height:160px;  margin-bottom:40px; "><img src="../assets/images/banner_sub_programs.png" style="border-bottom:2px solid #246180; width:100%; min-height:100%;"></div>
<div class="col-md-offset-3 col-md-6" style="min-height:500px;">
<!--<h5 style="font-size:28px; color:#333;">Mypath <span style="font-weight:bold;">프로그램 소개</span></h5>-->

    	<div style="main_content">
				<p style="font-size: 35px; padding-top: 50px;">우리는
						<span style="font-weight: 800; font-size: 35px;">모든 학생이 이해하길</span>&nbsp;원합니다.</p>
						<p style="font-size: 20px; padding: 10px 0;">레벨과 학년은 몇 단계지만, <span style="font-weight: 600; font-size: 20px;">학생들마다 어려운 영역은 수없이 다양합니다.</span> </p>
						<img src="../assets/images/img_line.png" style="padding: 20px 0;">
					  <p style="font-size: 20px; padding: 20px 0;">같은 레벨과 같은 학년 안에서도<br>개별 맞춤 학습이 이뤄지도록</p>

						<div>
						<div style="text-align: left; padding: 25px 0;  padding-left: 60px;">
							<img src="../assets/images/img_sub_brand_01_img.png" style="padding: 20px 0;">
							 <div style="display: inline-block; padding: 40px 70px; margin-top: 40px;">
								 <img src="../assets/images/mypath_use_logo.svg" style="padding: 20px 0;">
								 <span style="font-size: 18px; font-weight: 600;">영어를 처음 시작하는 학생부터</span>
							 </div>
						</div>


						<div style="text-align: right; padding: 25px 0;  padding-right:  60px; ">
							 <div style="display: inline-block; padding: 40px 70px; margin-top: 40px;">
								 <img src="../assets/images/mypath_logo.svg" style="padding: 20px 0;">
								 <span style="font-size: 18px; font-weight: 600;">영어가 이미 자유로운 학생들까지</span>
							 </div>
							<img src="../assets/images/img_sub_brand_02_img.png" style="padding: 20px 0;">
						</div>
						</div>


						<div class="eng_t">
							<p style="font-size: 30px; font-weight: bold;">Begin providing personalized learning for<br> your students</p>
						</div>
						<div class="" style="display:flex; margin: 0 auto;">
							<div class="" style="font-size: 17px; width: 490px; text-align: left; padding: 20px 30px;">
								Variation in proficiency levels within a single classroom demands new attention to education differentiation and intervention strategies. More than ever, teachers need access to technology and off-line tools that empower them to meet an array of needs with high-quality curriculum, adaptive instruction, actionable data, and resources targeted to individual students.
						   </div>
							<div class="" style="font-size: 17px; width: 490px; text-align: left; float: right; padding: 20px 30px;">
								Variation in proficiency levels within a single classroom demands new attention to education differentiation and intervention strategies. More than ever, teachers need access to technology and off-line tools that empower them to meet an array of needs with high-quality curriculum, adaptive instruction, actionable data, and resources targeted to individual students.
						   </div>
		  				</div>	<!--플렉스끝-->

					<div>
						<div class="eng_t">
							<p style="font-size: 30px; font-weight: bold;">Discover a Smarter Path for Student Success</p>
						</div>
						<div class="" style="font-size: 17px; width: 490px; text-align: left; float: left; padding: 20px 30px;">
							Across all grade levels and classrooms, students have a wide array of foundational skills and knowledge as well as different
							learning gaps and opportunities. Today’s teachers need access to technology and offline tools that empower them to meet an variety of student needs within a single classroom.
						</div>
						 <img src="../assets/images/1_1.png" style="width: 30%;">
					</div>
					<div>
						<div class="eng_t">
							<p style="font-size: 30px; font-weight: bold;">Discover a Better Path Forward</p>
						</div>
						<img src="../assets/images/1_2.png" style="width: 30%; float: left;">
						<div class="" style="font-size: 17px; width: 490px; text-align: right; float: right; padding: 20px 30px;">
							Across all grade levels and classrooms, students have a wide array of foundational skills and knowledge as well as different
							learning gaps and opportunities. Today’s teachers need access to technology and offline tools that empower them to meet an variety of student needs within a single classroom.
						</div>
					</div>

						<div style="text-align: center; clear: both; padding: 40px 0;">
							<img src="../assets/images/logo_color.png" style=" width: 40%;">
				        </div>
						<div class="" style="font-size: 17px; text-align: left;  padding: 20px 30px;">
							Personalized, Differentiated Instruction For K–12 MyPath® K–12 is Edgenuity’s next-level supplemental learning program for reading and math, personalizing learning according to each student’s assessed skill level and age. As a research-based online intervention program, MyPath® K–12 delivers an adaptive sequence of high-impact, prioritized lessons on a platform that promotes confidence, engagement, and conceptual understanding. Every lesson uses a gradual-release instructional model so students receive the exact instruction and practice they need at the moment they need it.
						</div>

				<div style="padding-bottom: 50px;">
				    <div class="" style="display:flex; margin: 0 auto;">
						<div class="eng_t" style="padding-bottom: 10px;">
							<p style="font-size: 30px; font-weight: bold; text-align: left;">Edgenuity’s Pathblazer Meets ESSA Tier 1 Evidence in Randomized Control Trial with Johns Hopkins University</p>
						</div>
				        <div style="width: 50%;">
							<img src="../assets/images/p1.png">
						</div>
					</div>

					<div style="background: #ededed; text-align: center; width: 49%; padding: 10px;">
								미국 현지언론매체에 소개된 mypath와 존스홉킨스 대학 관련기사
					</div>
				</div>

				<div style="padding-bottom: 50px;">
				<div class="eng_t">
					<p style="font-size: 30px; font-weight: bold;">PRWEB | Scottsdale, Arizona | June 24, 2020</p>
				</div>
					<div class="" style="font-size: 17px; text-align: left;  padding: 0 30px 20px;">
							PEdgenuity, a leading provider of online curriculum and instructional services for the K-12 market, today announced that its Pathblazer solution was found to meet the highest level – Tier 1 “strong” evidence – of efficacy in improving student achievement in reading for grades three to five, as outlined by the Every Student Succeeds Act (ESSA), which governs the United States K–12 public education policy. The Center for Research and Reform in Education (CRRE) at Johns Hopkins University measured the impact of Pathblazer on student reading achievement by conducting a cluster randomized controlled trial of Pathblazer during the 2019-2020 school year in Floyd County, Georgia. The study found that students who participated in Pathblazer outperformed similar control peers on the winter 2020 NWEA Measures of Academic Progress (MAP) Growth test in reading. Participation in Pathblazer was found to be particularly effective for students with low prior achievement and special education students. Teachers who participated in the study reported benefits for students across achievement levels. Pathblazer was shown to help low-achieving students fill in knowledge and skills gaps, and provided high-achieving, gifted students with access to more advanced content than they would have had otherwise.
						</div>
				<div style="background: #ededed; text-align: center; padding: 10px;">
				Pathblazer has also recently been recognized as the Best Literacy App or Tool by Tech Edvocate and the 2020 winner of best Personalized Learning Solution by EdTech Digest.
				</div>
				<div>
					<img src="../assets/images/j_1.png" style="width: 49%; padding: 10px;"><img src="../assets/images/j_1.png" style="width: 49%; padding: 10px;">
				</div>
			</div>

		</div><!--main_content 끝-->



</div>
</div>



</template>
